import React from 'react';
import { makeStyles, Typography } from '@material-ui/core'
import SEO from '../components/seo';
import Layout from '../components/layout';
import CalcBackground from '../components/calc_background2';
import ForwardIcon from '@material-ui/icons/Forward';
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon
} from '@material-ui/core';
import Qvc from '../components/qvc_code'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '80px'
  },
  servicesContainer: {
    marginTop: '30px'
  },
  headers: {
    color: '#FF0000',
    fontFamily: 'Shadows Into Light',
    textAlign: 'center',
    textDecoration: 'underline',
    paddingTop: '15px'
  },
  paper: {
    backgroundColor: '#F4F4F4',
    height: '500px',
    marginBottom: '30px'
  },
  taxPassInfo: {
    textAlign: 'center',
  },
  taxPassHeader: {
    marginTop: '60px',
    fontFamily: "Shadows Into Light",
    color: "#ff0000",
    textDecoration: "underline",
    textAlign: 'center'
  },
  taxPassPaper: {
    backgroundColor: '#F4F4F4',
    marginBottom: '30px'
  }
}))

const ServicesPage = () => {

  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Services"/>
      <div className={classes.root}>
        <CalcBackground/>
        <div className={classes.servicesContainer}>
          {/*I would like to have something here*/}

          <Grid container justify={'space-evenly'}>
            <Grid item xs={11} lg={5}>
              <Paper className={classes.paper}>
                <h1 className={classes.headers}>Services</h1>
                <List>
                  {[
                    "Tax Preparation Personal and Business",
                    "Building Business Credit",
                    "Applying for Business LLC",
                    "Apply for Business EIN",
                    "Mobile Services(We can come to you)"
                  ].map((item, i) => (
                    <ListItem>
                      <ListItemIcon>
                        <ForwardIcon color={'secondary'}/>
                      </ListItemIcon>
                      <ListItemText primary={item}/>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={11} lg={5}>
              <Paper className={classes.paper}>
                <h1 className={classes.headers}>Documents Needed</h1>
                <List>
                  {[
                    "I.D /D.L",
                    "S.S.C (Self & Dependents)",
                    "Check Stubs(Estimates)",
                    "W2s, 1099",
                    "Sch C(Business Information)"
                  ].map((item, i) => (
                    <ListItem>
                      <ListItemIcon>
                        <ForwardIcon color={'secondary'}/>
                      </ListItemIcon>
                      <ListItemText primary={item}/>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={10} justify={'center'}>
              <Paper className={classes.taxPassPaper}>
                <Grid container justify={'center'}>
                  <Grid item xs={10} lg={6}>
                    <h1 className={classes.taxPassHeader}>Download Tax Pass</h1>
                    <Typography variant={'body1'} component={'div'} className={classes.taxPassInfo}>
                      Upload and submit your W2s and other supporting documents within
                      a matter a minutes -- and all is a secure environment that
                      protects your important data. Scan the qr-code or download the
                      Tax Pass app to get started.
                    </Typography>
                    <a
                      href={"https://apps.apple.com/us/app/taxpass/id1256036789"}
                      style={{marginTop: '50px', fontFamily: "Shadows Into Light", color: "#ff0000", fontSize: '32px', display: 'block', cursor: 'pointer', textAlign: 'center'}}
                    >
                      Download Tax Pass
                    </a>
                  </Grid>
                  <Grid item xs={10} lg={6}>
                    <div style={{width: '60%', margin: '40px auto'}}>
                      <Qvc/>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  )
}




export default ServicesPage
